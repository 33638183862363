<template>
  <div class="paper-body-index">
    <!--<a class="banner" @click="clickBanner">
      <img src="@/assets/images/privlege/banner.jpg" alt />
    </a>-->

    <section class="version-wrap">
      <div
        v-for="(item,index) in list"
        :key="index"
        :class="index == 1 ? 'version recommended' : 'version'"
      >
        <p class="version__title">
          <span class="version__icon"></span>
          {{item.title}}
        </p>
        <p class="version__subtitle">授权主体：{{item.subTitle}}</p>
        <p class="version__price" v-if="index !== 2">
          素材
          <span>{{item.price}}</span> 折优惠
        </p>
        <a class="btn-wrap" @click="item.btnEve">{{item.buttonName}}</a>
       <!-- <router-link to="/memberactivity" class="member-activity" v-if="index == 0">
          <i></i>参与会员活动
        </router-link>
        <p class="use-range" v-if="index !== 2">
          <span>{{item.bannerName}}</span>
          <a @click="item.btnDetailEve">查看详情</a>
        </p>-->
        <ul class="version__featrue">
          <li class="featrue__item" v-for="(o,oIndex) in item.textList" :key="oIndex">{{o}}</li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "标准VIP",
          type:constants.vipType.standard,
          subTitle: "个人或企业",
          price: 9.5,
          buttonName: "立即开通",
          btnEve: () => {
            this.openStandardVIP();
          },
         // bannerName: "新媒体商用",
          btnDetailEve: () => {
            this.standardVIPDetails();
          },
          textList: [
            "所有素材9.5折优惠",
            "素材保存15天",
            "每月赠送优惠券",
          ],
          descText:"所有素材9.5折优惠、素材保存15天、每月赠送优惠券",
        },
        {
          title: "高级VIP",
          type:constants.vipType.senior,
          subTitle: "个人或企业",
          price: "9",
          buttonName: "立即开通",
          btnEve: () => {
            this.openSeniorVIP();
          },
          //bannerName: "线上+线下全商用",
          btnDetailEve: () => {
            this.seniorVIPDetails();
          },
          textList: [
            "所有素材9折优惠",
            "素材保存30天",
            "每月赠送优惠券",
            "VIP免费素材下载区",
             "专属客服",
          ],
          descText: "所有素材9折优惠、素材保存30天、每月赠送优惠券、VIP免费素材下载区、专属客服",
        },
        {
          title: "会员定制",
          subTitle: "企业",
          buttonName: "咨询客服",
          btnEve: () => {
            this.consulting();
          },
          textList: [
            "包含高级VIP所有权益",
            "定制可享优惠折扣"
          ]
        }
      ]
    };
  },
  methods: {
    //立即开通
    goMembers() {
      this.$router.push({
        path: "/members"
      });
    },
    //点击banner
    clickBanner() {
     // window.open("http://baidu.com");
    },
    //标准VIP-立即开通
    openStandardVIP() {
      let params = this.list[0];
      this.$store.dispatch("setVipTitle", params.title);
      this.$store.dispatch("setVipType", params.type);
      this.$store.dispatch("setVipSubTitle", params.descText);
      this.$store.dispatch("setVipBannerName", params.bannerName);
      this.goMembers();
    },
    //标准VIP-查看详情
    standardVIPDetails() {
      console.log("标准VIP-查看详情");
    },
    //高级VIP-立即开通
    openSeniorVIP() {
      let params = this.list[1];
      this.$store.dispatch("setVipTitle", params.title);
      this.$store.dispatch("setVipType", params.type);
      this.$store.dispatch("setVipSubTitle", params.descText);
      this.$store.dispatch("setVipBannerName", params.bannerName);
      this.goMembers();
    },
    //高级VIP-查看详情
    seniorVIPDetails() {
      console.log("高级VIP-查看详情");
    }
  }
};
</script>

<style lang="scss" scoped>
.paper-body-index {
  padding-top: 70px;
}
.banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 180px;
  cursor: pointer;
}
</style>
